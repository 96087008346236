<template>
  <span>
    <input
      id="newFileCamera"
      refs="newFileCamera"
      type="file"
      accept="image/*"
      :capture="capture ? capture : 'environment'"
      hidden
      @change="readURL"
    />
    <div class="containericone" @click="chooseFiles">
      <font-awesome-icon class="icone" :icon="['fa', 'camera']" />
    </div>
  </span>
</template>
<!-- eslint-disable -->
<script>
import Camera from 'easy-js-camera';
import imageFunctions from './../services/imageFunctions';
Camera.isCameraSupported();
export default {
  name: 'DashBoard',
  props: { capture: String },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      resizedImg: null,
    };
  },
  components: {},
  methods: {
    chooseFiles() {
      document.getElementById('newFileCamera').click();
    },
    readURL(event) {
      //console.log(file);
      var files = event.target.files[0];
      // START: preview original
      // you can remove this section if you don't like to preview original image

      const reader = new FileReader();
      reader.onload = (e) => (this.originalImg = e.target.result);
      reader.readAsDataURL(files); // convert to base64 string
      // END: preview original

      // START: preview resized
      imageFunctions
        .resizeImage({ file: files, maxSize: 1600 })
        .then((resizedImage) => {
          var obj = {
            base: resizedImage,
            blob: imageFunctions.dataURItoBlob(resizedImage),
            name: files.name,
          };
          console.log(obj);
          this.$emit('FotoTirada', obj);
        })
        .catch((err) => {
          console.log('Selecione uma Imagem');
          this.$bvToast.toast('Selecione uma Imagem', {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });
        });
      // END: preview resized
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.containericone {
  text-align: center;
  border-radius: 10px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
  width: 100px;
  border: 1px solid #e7e7e7;
  .icone {
    font-size: 50px;
    color: #333;
  }
}
</style>

<template>
  <span>
    <input
      id="newFileGaleria"
      refs="newFileGaleria"
      type="file"
      hidden
      @change="readURL"
    />
    <div class="containericone" @click="chooseFiles">
      <font-awesome-icon class="icone" :icon="['fa', 'folder']" />
    </div>
  </span>
</template>
<!-- eslint-disable -->
<script>
import imageFunctions from './../services/imageFunctions';
export default {
  name: 'DashBoard',
  props: {},
  data() {
    return {
      decoded: {},
      menuOpen: false,
      resizedImg: null,
    };
  },
  components: {},
  methods: {
    chooseFiles() {
      document.getElementById('newFileGaleria').click();
    },
    verificaExtensaoImagem(base) {
      if (base) {
        if (base.split('/')[0] == 'data:image') return true;
        var ext = base.split('.').pop();
        console.log(ext);
        if (ext == 'png' || ext == 'jpeg' || ext == 'jpg') return true;
        else return false;
      } else return false;
    },
    readURL(event) {
      //console.log(file);
      var files = event.target.files[0];
      console.log(files[0], event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(files); // convert to base64 string
      reader.onload = () => {
        if (this.verificaExtensaoImagem(reader.result)) {
          imageFunctions
            .resizeImage({ file: files, maxSize: 1600 })
            .then((resizedImage) => {
              var obj = {
                base: resizedImage,
                blob: imageFunctions.dataURItoBlob(resizedImage),
                name: files.name,
              };
              console.log(obj);
              this.$emit('FotoTirada', obj);
            });
        } else {
          var obj = {
            base: reader.result,
            blob: imageFunctions.dataURItoBlob(reader.result),
            name: files.name,
          };
          this.$emit('FotoTirada', obj);
        }
      };
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.containericone {
  text-align: center;
  border-radius: 10px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
  width: 100px;
  border: 1px solid #e7e7e7;
  .icone {
    font-size: 50px;
    color: #333;
  }
}
</style>
